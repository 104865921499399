import axios from "axios";
// export const login = async ({serverUrl="http://localhost:3000",userName, password} : {serverUrl?: string, userName: string, password: string}) => {

// export const login = async ({serverUrl="https://server.tamarprofile.site",userName, password} : {serverUrl?: string, userName: string, password: string}) => { //this url mack CORS error
// export const login = async ({serverUrl="https://translate-game-with-db.onrender.com",userName, password} : {serverUrl?: string, userName: string, password: string}) => {
export const login = async ({serverUrl,userName, password} : {serverUrl: string, userName: string, password: string}) => {
    try {
        console.log("hi from login function")
        if ( !userName || !password) throw new Error("please provide a valid username and password to login");

        console.log("at user-api login response from serverUrl is:", serverUrl)
        console.log("userName:",userName)
        console.log("password:",password)
        
        const response = await axios.post(`${serverUrl}/api/users/login`, {userName, password}, { withCredentials: true })
        console.log("at user-api login response from server is:", response)
        return response.data

        //return "ok: true" from server and userID encoded in cookie
    } catch (error) {
        console.error(error)
    }
} //work ok

export const register = async ({serverUrl="http://localhost:3000",userName, password} : {serverUrl?: string, userName: string, password: string}) => {
// export const register = async ({serverUrl="https://translate-game-with-db.onrender.com",userName, password} : {serverUrl?: string, userName: string, password: string}) => {
// export const register = async ({serverUrl,userName, password} : {serverUrl: string, userName: string, password: string}) => {
    try {
        if (!password || !userName) throw new Error("please provide a valid username and password to register");
        const response = await axios.post(`${serverUrl}/api/users/register`, { userName, password})
        console.log("at user-api register response from server is:", response.data.ok)
        if (!response.data.ok){
            alert("This username is already exist, sign-in or choose a different username")
            throw new Error(response.data);  
        }
        return response.data
        
    } catch (error) {
        console.error(error)
    }
}