import Cookies from "js-cookie";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import "../style/buttons.css";
import "../style/userPage.css";

const UserPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("user");
    navigate("/");
  };

  return (
    <>
      <div className="userPage-container">
        <h1 className="p-4 font-bold text-7xl drop-shadow-2xl">Welcome</h1>
        <h1 className="p-4 font-bold text-7xl drop-shadow-2xl">{user}</h1>
        <button className="logout" onClick={handleLogout}>
            LogOut
          </button>
        <div className="inner-container">
          <div>
            <button
              className="play text-2xl rounded-3xl"
              onClick={() => {
                navigate("/playGame/");
              }}
            >
              Play Now
            </button>
          </div>

          <div className="relative t-8 mt-12">
            <button
              className="m-3"
              onClick={() => {
                navigate("/tableTest");
              }}
            >
              Settings
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPage;
