import axios from 'axios';

//get all user word by userId
//userId in cookie
export const getAllUserWord = async (serverUrl: string) => {
// export const getAllUserWord = async ({serverUrl} : {serverUrl: string}) => {
// export const getAllUserWord = async ({serverUrl="http://localhost:3000"} : {serverUrl?: string}) => {
    try {
        console.log("At getAllUserWordByUserId the serverUrl:", serverUrl)
        console.log("At getAllUserWordByUserId the serverUrl type is:",typeof serverUrl)

        const response = await axios.get(`${serverUrl}/api/userWords/getAllUsersWords`, { withCredentials: true });
        console.log("At getAllUserWordByUserId the response:", response)
        const { ok, words } = response.data;
        console.log("At getAllUserWordByUserId the results:", words)
        if (ok) {
            return words;
        } else {
            console.error("Error retrieving words:", response.data.error);
        }
    } catch (error) {
        console.error("Error:", (error as Error).message);
    }
}; //work ok