import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./router/router";
import { ServerContext } from "./context/ServerUrlContext";
import { useState } from "react";
import { UserContext } from './context/userContext';

let environment = import.meta.env.MODE; // Automatically gets the mode (development or production)
const serverUrlDev = import.meta.env.VITE_API_SERVER_URL_DEV;
const serverUrlProd = import.meta.env.VITE_API_SERVER_URL_PROD;
const envServerUrl =
  environment === "development" ? serverUrlDev : serverUrlProd;

function App() {
  const [serverUrl] = useState<string>(envServerUrl);
  const [user, setUser] = useState<any>(null);
  console.log("At app.tsx server is:", serverUrl);
  console.log("At app.tsx user is:", user);
  
  return (
    <>
    <UserContext.Provider value={{ user, setUser }}>
      <ServerContext.Provider value={serverUrl}>
        <RouterProvider router={router} />
      </ServerContext.Provider>
    </UserContext.Provider>
    </>
  );
}

export default App;
